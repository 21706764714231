export enum userType {
  systemUser = 0, // 系统用户
  channelUser = 1, // 渠道用户
}

// 有无
export const  yesOrNo = [{
  label: '有',
  value: 1
}, {
  label: '无',
  value: 0
}]